/* eslint-disable */

//tslint:disable

import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  TSendValidationCodeModel,
  ISendValidationCodeRequest,
  IMappers,
  ISendValidationCodeResponse,
  ISendValidationCodeResponseError,
  TSendValidationCodeResponse,
} from './types';

const defaultConfig: TSendValidationCodeModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'deal-rent-announcements',
  pathApi: '/v1/send-validation-code/',
  requestType: 'json',
} as TSendValidationCodeModel;

function createSendValidationCodeModel(parameters: ISendValidationCodeRequest): TSendValidationCodeModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ISendValidationCodeOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: ISendValidationCodeRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchSendValidationCode<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: ISendValidationCodeOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TSendValidationCodeResponse
> {
  const { response, statusCode } = await httpApi.fetch(createSendValidationCodeModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as ISendValidationCodeResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as ISendValidationCodeResponseError);
    }
  }

  return { response, statusCode } as TSendValidationCodeResponse;
}

export { defaultConfig, createSendValidationCodeModel, fetchSendValidationCode };
