/* eslint-disable */

//tslint:disable

import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  TAddOfferToComparisonModel,
  IAddOfferToComparisonRequest,
  IMappers,
  IAddOfferToComparisonResponse,
  IAddOfferToComparisonResponseError,
  TAddOfferToComparisonResponse,
} from './types';

const defaultConfig: TAddOfferToComparisonModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'offers-comparison',
  pathApi: '/v2/add-offer-to-comparison/',
  hostType: 'api',
} as TAddOfferToComparisonModel;

function createAddOfferToComparisonModel(parameters: IAddOfferToComparisonRequest): TAddOfferToComparisonModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IAddOfferToComparisonOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IAddOfferToComparisonRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchAddOfferToComparison<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IAddOfferToComparisonOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TAddOfferToComparisonResponse
> {
  const { response, statusCode } = await httpApi.fetch(createAddOfferToComparisonModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IAddOfferToComparisonResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IAddOfferToComparisonResponseError);
    }
  }

  return { response, statusCode } as TAddOfferToComparisonResponse;
}

export { defaultConfig, createAddOfferToComparisonModel, fetchAddOfferToComparison };
