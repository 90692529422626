import { IState } from 'shared/store';

import { abUseExperimentsSelector, isAbUseExperimentsActive } from '../abUseExperiments';

export const PRICE_CLOSED_AUTH = 'aud_desktop_3819_price_closed_auth';

export const selectPriceClosedAuthExperimentEnabled = (state: IState) => {
  const experiments = abUseExperimentsSelector(state);

  return isAbUseExperimentsActive(experiments, PRICE_CLOSED_AUTH, 'B');
};
