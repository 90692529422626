import { createSelector } from '@reduxjs/toolkit';

import { EPromoType } from 'shared/types/newbuildingPromos';

import { getPromoInfos } from './getPromoInfos';

export const selectMainDiscountPromo = createSelector([getPromoInfos], promos => {
  if (!promos.length) {
    return null;
  }

  const promo = promos[0];

  return promo.promoType === EPromoType.Discount ? promo : null;
});
