import {
  NEWBUILDING_TRUST_AND_SAFETY,
  NEWBUILDING_RENT_TRAP,
  NEWBUILDING_SUBURBAN_TRAP,
  NEWBUILDING_FLAT_SALE_TRAP,
  NEWBUILDING_CONSULTANT_MESSAGE_BUTTON,
  PRICE_CLOSED_AUTH,
  AUD_HIDE_PHONE_NUMBER_EXPERIMENT,
} from 'shared/selectors/abExperiments';

export const EXPERT_OPINION = 'aud_desktop_aud3558_expert_opinion_gpt';

export const EXPERIMENTS: string[] = [
  NEWBUILDING_TRUST_AND_SAFETY,
  EXPERT_OPINION,
  NEWBUILDING_RENT_TRAP,
  NEWBUILDING_SUBURBAN_TRAP,
  NEWBUILDING_FLAT_SALE_TRAP,
  NEWBUILDING_CONSULTANT_MESSAGE_BUTTON,
  PRICE_CLOSED_AUTH,
  AUD_HIDE_PHONE_NUMBER_EXPERIMENT,
];
