/* eslint-disable */

//tslint:disable

import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  TChangePriceSubscribeModel,
  IChangePriceSubscribeRequest,
  IMappers,
  IChangePriceSubscribeResponse,
  IChangePriceSubscribeResponseError,
  TChangePriceSubscribeResponse,
} from './types';

const defaultConfig: TChangePriceSubscribeModel = {
  apiType: 'legacy',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'monolith-python',
  pathApi: '/cian-api/site/v1/change-price-subscribe/',
  hostType: 'api',
};

function createChangePriceSubscribeModel(parameters: IChangePriceSubscribeRequest): TChangePriceSubscribeModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IChangePriceSubscribeOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IChangePriceSubscribeRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchChangePriceSubscribe<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IChangePriceSubscribeOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TChangePriceSubscribeResponse
> {
  const { response, statusCode } = await httpApi.fetch(createChangePriceSubscribeModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IChangePriceSubscribeResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IChangePriceSubscribeResponseError);
    }
  }

  return { response, statusCode } as TChangePriceSubscribeResponse;
}

export { defaultConfig, createChangePriceSubscribeModel, fetchChangePriceSubscribe };
