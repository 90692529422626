import { HttpTimeoutError } from '@cian/peperrors/shared';

import { calculateParametersCombination } from './utils';
import { fetchGalleryMortgageWidgetMarkup } from '../../api/mortgage/fetch_gallery_mortgage_widget_markup';
import { IThunkActionCreator } from '../index';

export interface IMortgageGalleryState {
  mortgageGalleryWidgetMarkup?: string;
}

export interface IMortgageGalleryWidgetMarkupFetched {
  type: 'IMortgageGalleryWidgetMarkupFetched';
  payload: string;
}

export function mortgageGalleryStateReducer(
  state: IMortgageGalleryState,
  action: IMortgageGalleryWidgetMarkupFetched,
): IMortgageGalleryState {
  switch (action.type) {
    case 'IMortgageGalleryWidgetMarkupFetched':
      return {
        ...state,
        mortgageGalleryWidgetMarkup: action.payload,
      };
    default:
      return state || {};
  }
}

export function fetchMortgageGalleryWidget(cookie: string): IThunkActionCreator {
  return async (dispatch, getState, context) => {
    const { telemetry } = context;
    const {
      offerData: { offer },
    } = getState();

    let widgetMarkup = '';
    try {
      const mortgageGalleryWidgetApiUrl = context.config.getStrict<string>('mortgageGalleryWidgetApiUrl');
      const params = calculateParametersCombination(
        offer.bargainTerms.prices.rur,
        Boolean(offer.isApartment),
        Boolean(offer.newbuilding),
        offer.offerType,
      );

      if (!params) {
        return;
      }

      const mortgageGalleryWidgetUrl = `${mortgageGalleryWidgetApiUrl}?price=${params.price}&type=${params.type}&subtype=${params.subtype}`;

      const resp = await fetchGalleryMortgageWidgetMarkup(context.custom.makeRequest, mortgageGalleryWidgetUrl, cookie);

      widgetMarkup = resp.responseBody;
    } catch (err) {
      if (HttpTimeoutError.is(err)) {
        telemetry.increment('mortgage_widget_timeout_offer');
      }

      context.logger.warning('[Degradation] Financial widget gallery', err);
    }

    dispatch<IMortgageGalleryWidgetMarkupFetched>({
      type: 'IMortgageGalleryWidgetMarkupFetched',
      payload: widgetMarkup,
    });
  };
}
