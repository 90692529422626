import { IHttpApi } from '@cian/http-api';

import {
  fetchChangePriceSubscribe,
  IChangePriceSubscribeRequest,
  IChangePriceSubscribeResponse,
  IChangePriceSubscribeResponseError,
  TChangePriceSubscribeResponse,
} from '../../repositories/monolith-python/v1/change-price-subscribe';
import {
  fetchChangePriceUnsubscribe,
  IChangePriceUnsubscribeRequest,
  IChangePriceUnsubscribeResponse,
  IChangePriceUnsubscribeResponseError,
  TChangePriceUnsubscribeResponse,
} from '../../repositories/monolith-python/v1/change-price-unsubscribe';

export async function sendSubscriptionPriceChanges(
  httpApi: IHttpApi,
  subdomain: string,
  parameters: IChangePriceSubscribeRequest,
): Promise<IChangePriceSubscribeResponse | IChangePriceSubscribeResponseError> {
  const responseData: TChangePriceSubscribeResponse = await fetchChangePriceSubscribe({
    httpApi,
    parameters,
    config: {
      subdomain,
      requestConfig: {
        headers: [['Content-Type', 'application/json']],
      },
    },
  });

  if (responseData.statusCode === 400) {
    throw responseData.response;
  }

  return responseData.response;
}

export async function sendUnsubscriptionPriceChanges(
  httpApi: IHttpApi,
  subdomain: string,
  parameters: IChangePriceUnsubscribeRequest,
): Promise<IChangePriceUnsubscribeResponse | IChangePriceUnsubscribeResponseError> {
  const responseData: TChangePriceUnsubscribeResponse = await fetchChangePriceUnsubscribe({
    httpApi,
    parameters,
    config: {
      subdomain,
      requestConfig: {
        headers: [['Content-Type', 'application/json']],
      },
    },
  });

  if (responseData.statusCode === 400) {
    throw responseData.response;
  }

  return responseData.response;
}
