import { IState } from 'shared/store';

import { abUseExperimentsSelector, isAbUseExperimentsActive } from '../abUseExperiments';

export const AUD_HIDE_PHONE_NUMBER_EXPERIMENT = 'aud_desktop_aud3852_phone_auth_blocker';

export const selectIsHidePhoneNumberExperiment = (state: IState) => {
  const experiments = abUseExperimentsSelector(state);

  return isAbUseExperimentsActive(experiments, AUD_HIDE_PHONE_NUMBER_EXPERIMENT, 'B');
};
