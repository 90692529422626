import { requestOfferFromHistoryActions } from './actions';
import { AuthRequiredError } from '../../errors/AuthRequiredError';
import { fetchOfferFromHistory } from '../../services/offersHistory/fetchOfferFromHistory';
import { IThunkActionCreator } from '../../store';

export const getOfferFromHistory =
  (params: IParams): IThunkActionCreator<Promise<void>> =>
  async (dispatch, getState, context) => {
    dispatch(requestOfferFromHistoryActions.request());

    try {
      const data = await fetchOfferFromHistory(context, params);

      dispatch(
        requestOfferFromHistoryActions.success({
          id: params.cianId,
          data,
        }),
      );
    } catch (e) {
      if (AuthRequiredError.is(e)) {
        dispatch(requestOfferFromHistoryActions.authFailure());
      } else {
        dispatch(requestOfferFromHistoryActions.failure());
      }
    }
  };

interface IParams {
  cianId: number;
}
