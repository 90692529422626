import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import { fetchCreateRealtyByCianId } from 'shared/repositories/price-estimator/v1/create-realty-by-cian-id';
import { createAddMyHomeObjectMapper } from 'shared/services/valuationTraps/fetchAddToMyHomeObject/mappers';
import { IApplicationContext } from 'shared/types/applicationContext';

export const fetchAddToMyHomeObject = async (context: IApplicationContext, cianId: number, source?: string) => {
  try {
    const res = await fetchCreateRealtyByCianId({
      httpApi: context.httpApi,
      mappers: createAddMyHomeObjectMapper(),
      parameters: {
        cianId,
        source,
      },
    });

    if (res instanceof Error) {
      throw res;
    }

    return res;
  } catch (error) {
    if (HttpBadStatusCodeError.is(error)) {
      context.logger.debug('fetchAddToMyHomeObject BadRequest');
    } else {
      context.logger.error(error, { message: 'Error in fetchAddToMyHomeObject' });
    }

    throw error;
  }
};
