import { ca } from '@cian/analytics';

export function trackSubscriptionPriceSuccess(isShowPushCheckbox: boolean, isPushCheck?: boolean) {
  let label = 'From_card_ChangePrice';

  if (isShowPushCheckbox) {
    const pushOnOff = isPushCheck ? 'PushOn' : 'PushOff';

    label += `/${pushOnOff}`;
  }

  ca('eventSite', {
    action: 'Send',
    category: 'subscription',
    label,
    name: 'oldevent',
    pagetype: 'Card',
  });
}

export function trackSubscriptionRegistration() {
  ca('eventSite', {
    action: 'subscription',
    category: 'Registration',
    label: 'From_card_ChangePrice',
    name: 'oldevent',
  });
}

export function trackSubscriptionNewsSuccess() {
  ca('eventSite', {
    action: 'Send',
    category: 'subscription',
    label: 'news_From_card_ChangePrice',
    name: 'oldevent',
  });
}

export function trackUnsubscriptionSuccess() {
  ca('eventSite', {
    action: 'Send',
    category: 'unsubscription',
    label: 'From_card_ChangePrice',
    name: 'oldevent',
  });
}
