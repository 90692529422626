/* eslint-disable */

//tslint:disable

import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  TSubmitContactsModel,
  ISubmitContactsRequest,
  IMappers,
  ISubmitContactsResponse,
  ISubmitContactsResponseError,
  TSubmitContactsResponse,
} from './types';

const defaultConfig: TSubmitContactsModel = {
  apiType: 'public',
  assertStatusCodes: [400, 200, 204],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'newbuilding-reserved-offers',
  pathApi: '/v1/submit-contacts/',
  requestType: 'json',
} as TSubmitContactsModel;

function createSubmitContactsModel(parameters: ISubmitContactsRequest): TSubmitContactsModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ISubmitContactsOptions<TResponse400, TResponse200, TResponse204> {
  httpApi: IHttpApi;
  parameters: ISubmitContactsRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse400, TResponse200, TResponse204>;
}

async function fetchSubmitContacts<TResponse400, TResponse200, TResponse204>({
  httpApi,
  parameters,
  config,
  mappers,
}: ISubmitContactsOptions<TResponse400, TResponse200, TResponse204>): Promise<
  TResponse400 | TResponse200 | TResponse204 | TSubmitContactsResponse
> {
  const { response, statusCode } = await httpApi.fetch(createSubmitContactsModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 400) {
      return mappers[400](response as ISubmitContactsResponseError);
    }

    if (statusCode === 200) {
      return mappers[200](response as ISubmitContactsResponse);
    }

    if (statusCode === 204) {
      return mappers[204]();
    }
  }

  return { response, statusCode } as TSubmitContactsResponse;
}

export { defaultConfig, createSubmitContactsModel, fetchSubmitContacts };
